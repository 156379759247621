import React, { ForwardedRef, forwardRef } from 'react'

import classMerge from '../../utils/classMerge'

interface PopoverActionProps
  extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  className?: string
  children: React.ReactNode
}

const PopoverAction = forwardRef(
  ({ className, disabled, onClick, children, ...rest }: PopoverActionProps, ref: ForwardedRef<HTMLButtonElement>) => {
    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation()
      e.preventDefault()
      onClick?.(e)
    }

    return (
      <button
        ref={ref}
        onClick={handleClick}
        disabled={disabled}
        className={classMerge(
          'text-left p-2 rounded-md',
          {
            'text-neutral-200': disabled,
            'hover:bg-neutral-50': !disabled,
          },
          className
        )}
        {...rest}
      >
        {children}
      </button>
    )
  }
)

export default PopoverAction
