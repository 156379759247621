import React from 'react'

import BetaIcon from '../../icons/custom/Flask-Half--Streamline-Core.svg'
import classMerge from '../../utils/classMerge'
import BetaToolTip from './BetaToolTip'
import Tag from './Tag'

export interface BetaTagProps {
  disableTooltip?: boolean
  className?: string
}

const BetaTag = ({ className, disableTooltip = false }: BetaTagProps) => {
  return (
    <BetaToolTip disableTooltip={disableTooltip}>
      <Tag
        className={classMerge(
          className,
          'text-white bg-gradient-to-r from-tertiary-green-300 to-tertiary-green-200/70 items-center'
        )}
      >
        <BetaIcon className={classMerge('w-2.5 h-2.5 mr-1 fill-current')} />
        beta
      </Tag>
    </BetaToolTip>
  )
}

export default BetaTag
