import React from 'react'

import classMerge from '../../utils/classMerge'

export interface InputFieldProps {
  children: React.ReactNode
  className?: string
}

const InputField = ({ children, className }: InputFieldProps) => {
  return <div className={classMerge('flex flex-col font-normal', className)}>{children}</div>
}

export default InputField
