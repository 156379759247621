import { IconButton } from '@packages/sk8/button'
import { BetaTag } from '@packages/sk8/tag'
import { SpringValue, animated } from '@react-spring/web'
import React from 'react'

import AddIcon from 'icons/bold/01-Interface Essential/43-Remove-Add/add.svg'
import CornerArrow from 'icons/core-solid/interface-essential/interface-arrows-bend-down-right-1.svg'
import DownArrow from 'icons/core-solid/interface-essential/interface-arrows-right.svg'
import BulkOrderBuilder from 'img/login/bulk-order-builder.png'
import BulkOrder from 'img/login/bulk-order.png'

interface BulkFeatureProps {
  style: Omit<React.CSSProperties, 'transform'> & { transform: SpringValue<string> }
}

const BulkFeature = ({ style }: BulkFeatureProps) => {
  return (
    <animated.div style={style} className="h-full w-full bg-[#FFB421]">
      <div className="flex-col h-full w-full flex items-center justify-center">
        <div className="relative mb-20">
          <div className="w-[480px] border border-white rounded-lg overflow-hidden shadow-loginImage bottom-24 backdrop-blur-[1px] bg-white/95">
            <img src={BulkOrder} />
          </div>
          <div className="absolute top-0 left-1/3 scale-90 -translate-x-1/2 -translate-y-[70%] flex space-x-4 items-center">
            <IconButton
              className="!shadow-loginImage z-20 opacity-90"
              aria-label="Add question"
              Icon={AddIcon}
              smallIcon
              variant="primary"
            />
            <DownArrow className="w-4 h-4 " />
            <div className=" overflow-hidden rounded-lg shadow-loginImage border border-white bg-white/80">
              <img src={BulkOrderBuilder} />
            </div>
            <CornerArrow className="w-[18px] h-[18px] mt-2" />
          </div>
        </div>
        <div className="flex flex-col max-w-md text-center">
          <h1 className="mb-6 text-center ">
            <BetaTag className="mr-2" disableTooltip />
            Bulk/Team order
          </h1>
          <p className="mb-4 text-neutral-700 leading-[1.125rem]">
            Your customers can now order multiple items in a single customization experience!
          </p>
        </div>
      </div>
    </animated.div>
  )
}

export default BulkFeature
