import React from 'react'

import classMerge from '../../utils/classMerge'

export interface LabelProps {
  htmlFor?: string
  className?: string
  children: React.ReactNode
}

const Label = ({ htmlFor, children, className }: LabelProps) => {
  return (
    <label className={classMerge('mb-2', className, { 'hover:cursor-pointer': htmlFor != null })} htmlFor={htmlFor}>
      {children}
    </label>
  )
}

export default Label
