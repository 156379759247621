import React from 'react'

import HelpIcon from '../../icons/regular/01-Interface Essential/14-Alerts/question-circle.svg'
import classMerge from '../../utils/classMerge'

const AlertLink = ({
  children,
  className,
  ...props
}: React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>) => {
  return (
    <button {...props} className={classMerge('flex items-center text-neutral-900', className)}>
      {children}
      <span className="ml-2">
        <HelpIcon className="w-3.5 h-3.5" />
      </span>
    </button>
  )
}

export default AlertLink
