import React from 'react'

import classMerge from '../../utils/classMerge'

const CardFooter = ({
  children,
  className,
  ...props
}: React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>) => (
  <footer
    className={classMerge('flex justify-end border-t border-neutral-75 px-6 py-3 space-x-3', className)}
    {...props}
  >
    {children}
  </footer>
)

export default CardFooter
