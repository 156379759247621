import React from 'react'

import classMerge from '../../utils/classMerge'

const CardSeparator = ({
  className,
  ...props
}: React.DetailedHTMLProps<React.HTMLAttributes<HTMLHRElement>, HTMLHRElement>) => (
  <hr {...props} className={classMerge('border-neutral-100', className)} />
)

export default CardSeparator
