import React from 'react'

import classMerge from '../utils/classMerge'

interface SpinnerProps {
  className?: string
}

const Spinner = ({ className }: SpinnerProps) => (
  <div
    aria-label="loading"
    role="presentation"
    className={classMerge('border-2 animate-spin w-4 h-4 rounded-full', className, 'border-t-transparent')}
  />
)

export { Spinner }
