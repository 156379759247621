import { BetaTag } from '@packages/sk8/tag'
import { SpringValue, animated } from '@react-spring/web'
import React from 'react'

import Inventory from 'img/login/inventory.png'
import OutOfStock from 'img/login/out-of-stock.png'

interface InventoryFeatureProps {
  style: Omit<React.CSSProperties, 'transform'> & { transform: SpringValue<string> }
}

const InventoryFeature = ({ style }: InventoryFeatureProps) => {
  return (
    <animated.div style={style} className="h-full w-full bg-primary-600">
      <div className="flex-col h-full w-full flex items-center justify-center">
        <div className="relative mb-20">
          <div className="w-[480px] border border-white rounded-lg overflow-hidden shadow-loginImage bottom-24 backdrop-blur-[1px] bg-white/95">
            <img src={Inventory} />
          </div>
          <div className="absolute overflow-hidden w-60 h-20 rounded-lg shadow-loginImage border border-white left-4 xl:left-auto xl:-right-4 -bottom-6 bg-white/90 backdrop-blur-[1px]">
            <img src={OutOfStock} className="min-w-fit h-20" />
          </div>
        </div>
        <div className="flex flex-col">
          <h1 className="mb-6 text-center text-white">
            <BetaTag className="mr-2" disableTooltip />
            Inventory management
          </h1>
          <p className="mb-4 text-white/90 leading-[1.125rem]">
            Now, managing your custom products inventory is a breeze!
          </p>
        </div>
      </div>
    </animated.div>
  )
}

export default InventoryFeature
